import range from 'lodash/range';
import map from 'lodash/map';
import React from 'react';
import CountUp from 'react-countup';
import Typography from '@mui/material/Typography';
import {
  CollectionPriorityCell,
  DateWithDaysDiffCell,
  StatusSummaryStepper,
} from '../components/dataGridCells';
import DrawerHeader from '../components/drawer/DrawerHeader';
import DateCell from '../components/cells/DateCell';
import LinkButton from '../components/buttons/LinkButton';
import CollectionActions from '../components/drawer/CollectionActions';
import { ActionDrawerCell, AmountWithIvaCell, LinkCell, MasterEntityNameCell } from '../components/cells';
import { collectionPriorityColors } from '.';
import { getSerializedSearch } from '../helpers';

const dateGroupToText = {
  0: 'Más de 90',
  1: 'Hace 90 a 60',
  2: 'Hace 60 a 30',
  3: 'Hace 30 a hoy',
  4: 'En  hoy a 30',
  5: 'En 30 a 60',
  6: 'En 60 a 90',
  7: 'En más de 90',
};

export const collectionSummaryAdaptData = (data, key) => {
  const findPriority = (values, priorityValue) => {
    const priorityFinder = values.find(
      ({ priority }) => priority === priorityValue,
    );
    return priorityFinder ? priorityFinder[key] : 0;
  };
  const copyData = data.map(({ dateGroup, values }) => ({
    dateGroup,
    priority1: findPriority(values, 1),
    priority2: findPriority(values, 2),
    priority3: findPriority(values, 3),
    priority4: findPriority(values, 4),
    priority5: findPriority(values, 5),
  }));
  range(8).forEach((dateGroup) => {
    if (!map(data, 'dateGroup').includes(dateGroup)) {
      copyData.push({
        dateGroup,
        priority1: 0,
        priority2: 0,
        priority3: 0,
        priority4: 0,
        priority5: 0,
      });
    }
  });
  const chartData = copyData.map((dateRangeData) => ({
    name: dateGroupToText[dateRangeData.dateGroup],
    ...dateRangeData,
  }));
  chartData.sort((a, b) => a.dateGroup - b.dateGroup);
  return chartData;
};

export const buildSummaryArray = (
  {
    expiredCount,
    expiredAmount,
    unexpiredCount,
    unexpiredAmount,
  },
) => {
  const expiredInt = parseInt(expiredAmount, 10);
  const unexpiredInt = parseInt(unexpiredAmount, 10);
  const totalCount = parseInt(expiredCount, 10) + parseInt(unexpiredCount, 10);
  const totalAmount = expiredInt + unexpiredInt;

  return [
    {
      label: 'Total facturas por cobrar',
      percent: '',
      percentColor: null,
      amount: totalCount,
      gridSx: { color: '' },
    },
    {
      label: 'Total en cobranza',
      percent: '',
      percentColor: null,
      amount: totalAmount,
      gridSx: { color: '' },
    },
    {
      label: 'Vencido',
      percent: totalAmount > 0 ? `${Math.trunc((expiredInt / totalAmount) * 100)} %` : '0%',
      percentColor: collectionPriorityColors[1],
      amount: expiredInt,
      gridSx: { color: '' },
    },
    {
      label: 'Proximo a vencer',
      percent:
        totalAmount > 0 ? `${Math.trunc((unexpiredInt / totalAmount) * 100)} %` : '0%',
      percentColor: collectionPriorityColors[3],
      amount: unexpiredInt,
      gridSx: { color: '' },
    },
  ];
};

export const PORTFOLIO_OPTIONS = ['Cartera vigente', 'Cartera histórica'];

export const CURRENT_PORTFOLIO_HEADERS = [
  'folio',
  'company_MasterEntity_Name',
  'dateIssued',
  'amountWithIva',
  'dateExpiration',
  'agreedDateToPay',
  'siiStatus',
  'collectionManagerRelation_CollectionPriority_Value',
  'collectionActions',
];

export const CURRENT_PORTFOLIO_MINIMAL_HEADERS = [
  'folio',
  'receiver',
  'dateIssued',
  'amountWithIva',
];

export const CURRENT_PORTFOLIO_INITIAL_ORDER_BY = 'collectionManagerRelation_CollectionPriority_Value';

export const HISTORIC_PORTFOLIO_HEADERS = [
  'folio',
  'company_MasterEntity_Name',
  'dateIssued',
  'amountWithIva',
  'dateExpiration',
  'siiStatus',
  'collectionActions',
];

export const HISTORIC_PORTFOLIO_INITIAL_ORDER_BY = 'paymentDate';

export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <LinkButton
        to={{
          pathname: '/app/invoice',
          search: `?invoiceId=${row.id}`,
        }}
        tooltipTitle={row.folio.length > 10 ? row.folio : ''}
        label={row.folio}
      />
    ),
  },
  {
    field: 'dateIssued',
    type: 'date',
    headerName: 'Emisión',
    headerAlign: 'center',
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'company_MasterEntity_Name',
    headerName: 'Emisor',
    type: 'string',
    flex: 1,
    renderCell: (params) => (
      <MasterEntityNameCell
        masterEntity={params.row.company.masterEntity}
        loanedStatus={params.row.loanedStatus}
        loanedTo={params.row.loanedTo}
      />
    ),
  },
  {
    field: 'receiver',
    headerName: 'Deudor',
    type: 'string',
    flex: 1,
    renderCell: (params) => (
      <MasterEntityNameCell
        masterEntity={params.row.receiver}
        loanedStatus={params.row.loanedStatus}
        loanedTo={params.row.loanedTo}
      />
    ),
  },
  {
    field: 'amountWithIva',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },

  {
    field: 'dateExpiration',
    headerName: 'Fecha de pago según factura',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    minWidth: 250,
    flex: 1,
    valueGetter: ({ row }) => row.dateExpiration,
    renderCell: ({ value }) => <DateWithDaysDiffCell date={value} useColors />,
  },
  {
    field: 'agreedDateToPay',
    headerName: 'Fecha de pago confirmada por el cliente',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    minWidth: 260,
    valueGetter: ({ row }) => row.collectionManager?.currentDataForCollection?.dateToPay,
    renderCell: ({ value }) => <DateWithDaysDiffCell date={value} useColors />,
  },
  {
    field: 'siiStatus',
    type: 'singleSelect',
    headerName: 'Estado',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: ({ row }) => <StatusSummaryStepper invoice={row} />,
  },
  {
    field: 'collectionManagerRelation_CollectionPriority_Value',
    headerName: 'Prioridad',
    valueGetter: ({ row }) => row.collectionManager.collectionPriority.value,
    renderCell: ({ row }) => (
      <CollectionPriorityCell priority={row.collectionManager.collectionPriority.value} />
    ),
  },
  {
    field: 'collectionActions',
    headerName: 'Gestiones',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <ActionDrawerCell
        title={`Gestiones de cobranza factura ${row.folio}`}
        actions={
          row.collectionManager
            ? row.collectionManager.actions.filter((action) => action.actionType !== 'OTHER')
            : []
        }
        headerComponent={DrawerHeader}
        contentComponent={CollectionActions}
        drawerId={row.folio}
      />
    ),
  },
];

export const CONTACT_COLUMNS = [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    width: 100,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'position',
    headerName: 'Cargo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Correo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 1,
  },
];

export const ACTIONS_OPTIONS = [
  {
    label: 'Llamado telefónico al receptor del documento',
    value: 'PHONE_CALL_TO_RECEIVER',
    enabled: 'debtor',
  },
  { label: 'Mail al receptor', value: 'MAIL_TO_RECEIVER', enabled: 'debtor' },
  {
    label: 'Envío formulario de pago al receptor',
    value: 'RECEIVER_FORM_MAIL',
    enabled: 'debtor',
  },
  { label: 'Agregar gestión genérica', value: 'OTHER', enabled: 'always' },
];

export const INFORMATION_TOOLTIP_TEXT = `En este panel verás la información del cliente en la parte
superior y las facturas por cobrar en la tabla inferior. Selecciona la factura que desees 
gestionar para luego agregar gestión, enviar nuevo mail, obtener información de la recaudación o 
marcarla como pagada.`;

export const TOUR_GUIDE_STEPS = [
  {
    content: `En este rectángulo ves la información de contacto del cliente y el detalle de deuda 
    total y pagos.`,
    target: '#debtor-summary',
    placement: 'auto',
  },
  {
    content: `Con estos 4 botones puedes realizar acciones con respecto a la cobranza. 
    Selecciona una o más facturas y agrégale una nueva gestión, envía un nuevo mail, visualiza 
    información de recaudación y/o márcalas como pagadas.`,
    target: '#debtor-actions',
    placement: 'auto',
  },
  {
    content: `Visualiza las facturas por cobrar a tus clientes. Haz click en el botón de Folio 
    para ver detalles acerca del PDF de la factura, el estado en el que se encuentra, la 
    prioridad de cobranza y el historial de gestiones realizadas.`,
    target: '#datagrid-box',
    placement: 'auto',
  },
];

export const PRIORITY_COLUMNS_KOBROS = [
  {
    field: 'collectionPriority',
    headerName: 'Prioridad',
    renderCell: ({ row }) => (
      <LinkCell
        to={{
          pathname: '/app/sales/collection/debtors',
          search: getSerializedSearch({
            debtor_CollectionPriorities_Priority_Value: row.collectionPriority,
          }),
        }}
        label={`Prioridad ${row.collectionPriority}`}
        color={collectionPriorityColors[row.collectionPriority]}
      />
    ),
    flex: 1.2,
  },
  { field: 'count', headerName: 'Documentos', flex: 1 },
  { field: 'debtors', headerName: 'Clientes', flex: 1 },
  {
    field: 'amount',
    headerName: 'Monto',
    renderCell: ({ row }) => (
      <Typography variant="body1">
        $<CountUp separator="." end={row.amount} duration={1} />
      </Typography>
    ),
    flex: 1.5,
  },
];

export const PRIORITY_COLUMNS = [
  {
    field: 'collectionPriority',
    headerName: 'Prioridad',
    renderCell: ({ row }) => (
      <LinkCell
        to={{
          pathname: '/app/collection/debtors',
          search: getSerializedSearch({
            debtor_CollectionPriorities_Priority_Value: row.collectionPriority,
          }),
        }}
        label={`Prioridad ${row.collectionPriority}`}
        color={collectionPriorityColors[row.collectionPriority]}
      />
    ),
    flex: 1.2,
  },
  { field: 'count', headerName: 'Documentos', flex: 1 },
  { field: 'debtors', headerName: 'Clientes', flex: 1 },
  {
    field: 'amount',
    headerName: 'Monto',
    renderCell: ({ row }) => (
      <Typography variant="body1">
        $<CountUp separator="." end={row.amount} duration={1} />
      </Typography>
    ),
    flex: 1.5,
  },
];
